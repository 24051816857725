import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function NoData({ text }) {
  return (
    <Box
      component="em"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={75}
    >
      {text}
    </Box>
  );
}

NoData.propTypes = {
  text: PropTypes.string.isRequired,
};
