import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

import SelectField from '~/components/Select';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import AttestListResult from './AttestListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  paymentService: 'billecta',
  from: '',
  to: '',
  status: 'open',
};

export default function AttestList() {
  const classes = useStyles();
  const location = useLocation();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);
  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box display="grid" gridAutoFlow="column" gridColumnGap="10px">
          <SelectField
            className={classes.textField}
            label="Betaltjänst"
            value={state.paymentService}
            onChange={e => handleChange('paymentService', e.target.value)}
            options={[
              { value: 'all', label: 'Alla' },
              { value: 'swedbankpay', label: 'SwedbankPay' },
              { value: 'billecta', label: 'Billecta' },
            ]}
          />
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          <SelectField
            className={classes.textField}
            label="Status"
            value={state.status}
            onChange={e => handleChange('status', e.target.value)}
            options={[
              { value: '', label: 'Alla' },
              { value: 'open', label: 'Behöver attesteras' },
              { value: 'closed', label: 'Återbetalad' },
            ]}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={() => ({
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <AttestListResult params={params} />
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/refunds${location.search}`}
            rel="noreferrer"
          >
            Ladda ner
          </Button>
        </Box>
      </Box>
    </>
  );
}
