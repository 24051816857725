import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';
import { distributableProducts } from 'trhub-utils';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetEdition.gql';
import DistributionList from './DistributionList';
import EditionDetail from './EditionDetail';
import EditionEdit from './EditionEdit';

export default function Edition() {
  const { path } = useRouteMatch();
  const { edition } = useParams();
  const { error, loading, data } = useQuery(query, {
    variables: { edition },
  });
  const [hasError, setHasError] = useState(false);

  // Handlechange for search component
  const handleHasErrorChange = useCallback(value => {
    setHasError(value);
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb
        name={`${distributableProducts[data.getEdition.sku]} / ${
          data.getEdition.editionLabel
        }`}
      />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <EditionDetail editionData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/edit`} exact>
          <EditionEdit editionData={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/distribution`} exact>
          <DistributionList
            editionData={data}
            hasError={hasError}
            handleHasErrorChange={handleHasErrorChange}
          />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
