import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, setMonth } from 'date-fns';
import { sv } from 'date-fns/locale';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetSubscriptionStats.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function SubscriptionStatsListResults({ params }) {
  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const { error, data } = useQuery(query, {
    variables: {
      site: params.site,
      productIds:
        Array.isArray(params.productIds) && params.productIds.length > 0
          ? params.productIds
          : typeof params.productIds === 'string' &&
              params.productIds.length > 0
            ? [params.productIds]
            : undefined,
      from: params.from || undefined,
      to: params.to || undefined,
      dateType: params.dateType,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getSubscriptionStats;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }
  let dateHeader = 'Datum';
  switch (res.dateType) {
    case 'weekly':
      dateHeader = 'Vecka';
      break;
    case 'monthly':
      dateHeader = 'Månad';
      break;
    case 'yearly':
      dateHeader = 'År';
      break;
    default:
      break;
  }
  const headers = [
    dateHeader,
    'Nya',
    'Återaktiverad avbruten',
    'Inaktiva',
    'Återaktiverad passiv',
    'Avbrutna',
    'Avslutade',
    'Churn',
    'Aktiva',
    'Totalt',
  ];
  const helpTexts = [
    null,
    'Användare som inte har varit prenumeranter på denna produkt tidigare',
    'Användare som har valt att fortsätta sin prenumeration innan slutdatumet har passerat',
    'Användare som har varit prenumeranter tidigare',
    'Användare som tidigare har varit prenumerant på produkten, och har påbörjat en ny prenumeration av samma produkt detta datumet',
    'Användare som har satt ett kommande slutdatum, men har access för tillfället',
    'Användare som avslutade prenumerationen detta datumet',
    'Churn: Avslutade / Total i procent',
    'Användare som är tillsvidare prenumeranter, inget slutdatum satt',
    'Totala användare: Aktiva + Avbrutna',
  ];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        helpTexts={helpTexts}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.reports.length > 0 ? (
          res.reports.map((row, index) => {
            let dateLabel = row.date;
            if (res.dateType === 'daily') {
              dateLabel = <Time date={new Date(row.date)} />;
            } else if (res.dateType === 'monthly') {
              dateLabel = format(setMonth(new Date(), row.date - 1), 'LLLL', {
                locale: sv,
              });
            }
            return (
              <TableRow hover key={index}>
                <TableCell>{dateLabel}</TableCell>
                <TableCell>{row.newSubscribers}</TableCell>
                <TableCell>{row.reactivatedEndedSubscribers}</TableCell>
                <TableCell>{row.inactiveSubscribers}</TableCell>
                <TableCell>{row.reactivatedPassiveSubscribers}</TableCell>
                <TableCell>{row.cancelledSubscribers}</TableCell>
                <TableCell>{row.endedSubscribers}</TableCell>
                <TableCell>{row.churn}%</TableCell>
                <TableCell>{row.activeSubscribers}</TableCell>
                <TableCell>{row.totalSubscribers}</TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={10} align="center">
              Ingen prenumerationsstatistik finns för denna filtrering. Prova
              att ange en annan produkt, eller ett annat datumintervall.
            </TableCell>
          </TableRow>
        )}
        {res?.reports.length > 0 && (
          <TableRow className={classes.tableRowSum}>
            <TableCell>Total:</TableCell>
            <TableCell>{res.summary.newSubscribers}</TableCell>
            <TableCell>{res.summary.reactivatedEndedSubscribers}</TableCell>
            <TableCell>{res.summary.inactiveSubscribers}</TableCell>
            <TableCell>{res.summary.reactivatedPassiveSubscribers}</TableCell>
            <TableCell>{res.summary.cancelledSubscribers}</TableCell>
            <TableCell>{res.summary.endedSubscribers}</TableCell>
            <TableCell>{res.summary.churn}%</TableCell>
            <TableCell>{res.summary.activeSubscribers}</TableCell>
            <TableCell>{res.summary.totalSubscribers}</TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

SubscriptionStatsListResults.propTypes = {
  params: PropTypes.shape({
    site: PropTypes.string,
    productIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    from: PropTypes.string,
    to: PropTypes.string,
    dateType: PropTypes.string,
  }).isRequired,
};
