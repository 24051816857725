import {
  Autorenew,
  CreditCard,
  MailOutline,
  MoneyOff,
  PhoneIphone,
  Receipt,
} from '@material-ui/icons';
import PropTypes from 'prop-types';

const paymentIcons = {
  card: CreditCard,
  swish: PhoneIphone,
  autogiro: Autorenew,
  invoice: Receipt,
  payexInvoice: Receipt,
  nets: CreditCard,
  notification: MailOutline,
  free: MoneyOff,
};

const PaymentMethodIcon = ({ paymentMethod }) => {
  const Icon = paymentMethod && paymentIcons?.[paymentMethod];
  return Icon ? <Icon fontSize="small" /> : null;
};

PaymentMethodIcon.propTypes = {
  paymentMethod: PropTypes.string,
};

export default PaymentMethodIcon;
