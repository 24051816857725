import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { formatMoney } from '@trmediaab/zebra-utils';
import { Link as RouterLink } from 'react-router-dom';

import NoData from '~/components/NoData';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { orderPropType } from '~/propTypes';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderRefunds({ order }) {
  const { refunds } = order;
  return (
    <TablePaper title="Återbetalningar">
      {refunds.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Belopp</TableCell>
                <TableCell>Kreditorder</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refunds.map(
                item =>
                  item && (
                    <StyledTableRow key={item.id}>
                      <TableCell>
                        <Time
                          date={new Date(item.paidDate)}
                          format="yyyy-MM-dd HH:mm"
                        />
                      </TableCell>
                      <TableCell>{formatMoney(0 - item.totalPrice)}</TableCell>
                      <TableCell>
                        <Link component={RouterLink} to={`/order/${item.id}`}>
                          {item.id}
                        </Link>
                      </TableCell>
                    </StyledTableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData text="Den här ordern saknar återbetalningar" />
      )}
    </TablePaper>
  );
}

OrderRefunds.propTypes = {
  order: orderPropType,
};
