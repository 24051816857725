import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';

import NoData from '~/components/NoData';
import TableList from '~/components/TableList';

export default function MissingUsers({ missingUsers }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = event => {
    setPage(event);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headers = ['Användar-id'];

  return (
    <TableList
      title="Användare saknas"
      page={page + 1}
      data={{
        items: missingUsers,
        total: missingUsers.length,
      }}
      pageSize={rowsPerPage}
      headers={headers}
      updatePage={handleChangePage}
      updateRowSize={handleChangeRowsPerPage}
    >
      {missingUsers
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(user => (
          <TableRow key={user}>
            <TableCell>{user}</TableCell>
          </TableRow>
        ))}

      {missingUsers.length === 0 && (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Det finns inga användare som saknas" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

MissingUsers.propTypes = {
  missingUsers: PropTypes.arrayOf(PropTypes.string),
};
