import { TableCell, TableRow } from '@material-ui/core';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { useState } from 'react';

import NoData from '~/components/NoData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';

const DebtList = ({ debtList }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = event => {
    setPage(event);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headers = ['Period', 'Nettoskuld', 'Bruttoskuld'];

  return (
    <TableList
      title="Prenskuld"
      page={page + 1}
      data={{
        items: debtList,
        total: debtList.length,
      }}
      pageSize={rowsPerPage}
      headers={headers}
      updatePage={handleChangePage}
      updateRowSize={handleChangeRowsPerPage}
    >
      {debtList
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(debt => (
          <TableRow key={debt}>
            <TableCell>
              <Time date={new Date(debt.generated)} format="yyyy-MM" />
            </TableCell>
            <TableCell>{formatMoney(debt.netAmount)}</TableCell>
            <TableCell>{formatMoney(debt.amount)}</TableCell>
          </TableRow>
        ))}

      {debtList.length === 0 && (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Det finns ingen skuld för denna prenumeration" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
};

DebtList.propTypes = {
  debtList: PropTypes.array,
};

export default DebtList;
