import { useApolloClient, useMutation } from '@apollo/client';
import { Box, Button, Input } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';

import mutation from './_LoginAsUserLink.gql';

export default function LoginAsUser({ userId }) {
  const apollo = useApolloClient();

  const [error, setError] = useState('');

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const handleLoginAsUser = useCallback(async () => {
    const response = await action({
      variables: {
        id: userId,
      },
    }).catch(responseError => setError(responseError));

    if (response?.data?.loginAsUserLink) {
      setLoginLink(response.data.loginAsUserLink);
      return;
    }

    setOpen(false);
  }, [action, userId]);

  const [loginLink, setLoginLink] = useState('');

  const [open, setOpen] = useState(loginLink ? true : false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        css={({ theme }) => ({
          backgroundColor: theme.palette.info.main,
          color: theme.palette.text.main,
          '&:hover': {
            background: theme.palette.info.dark,
          },
        })}
        onClick={handleClickOpen}
        variant="contained"
      >
        Logga in som
      </Button>
      {error ? (
        <Box
          css={({ theme }) => ({
            marginTop: theme.spacing(2),
          })}
        >
          <ErrorMessage error={error} />
        </Box>
      ) : (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Logga in som användare</DialogTitle>
          <DialogContent>
            <DialogContentText>
              En länk för att logga in som användare kommer att skapas.
            </DialogContentText>
            <DialogContentText>
              Kopiera länken och öppna ett nytt inkognito-fönster
              (Cmd/Ctrl+Shift+N), och klistra in länken.
            </DialogContentText>
            <DialogContentText>
              <Input readOnly fullWidth value={loginLink} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Avbryt
            </Button>
            <Button
              onClick={handleLoginAsUser}
              css={({ theme }) => ({
                color: theme.palette.error.main,
              })}
            >
              Skapa länk
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

LoginAsUser.propTypes = {
  userId: PropTypes.string.isRequired,
};
