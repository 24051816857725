import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

import useQueryParams from '~/hooks/useQueryParams';
import query from '~/utils/_GetProducts.gql';
import shallowEqual from '~/utils/shallowEqual';

import GiftCodeDebtListResult from './GiftCodeDebtListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  autoComplete: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  selectTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  month: format(new Date(), 'yyyy-MM'),
};

export default function GiftCodeDebtList() {
  const classes = useStyles();

  const { state, params, handleChange, clear } = useQueryParams(initialValues);
  const location = useLocation();

  const { error, data } = useQuery(query, {
    variables: {
      limit: 150,
      filter: { productType: 'subscription', giftProduct: true },
    },
  });

  const productList = data?.list?.items ?? [];

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!productList) {
    return <CircularProgress />;
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Värdekodsskuld
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="month"
            value={state.month}
            onChange={e => handleChange('month', e.target.value)}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={() => ({
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>

      <Box className={classes.box}>
        <GiftCodeDebtListResult params={params} />
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export/gift-debt${location.search}`}
            rel="noreferrer"
          >
            Ladda ner
          </Button>
        </Box>
      </Box>
    </>
  );
}
