import { useMutation } from '@apollo/client';
import { Box, IconButton, Link } from '@material-ui/core';
import { Check, Delete } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { formatMoney } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DataGrid from '~/components/DataGrid';
import getOpenRefundsCount from '~/components/Layout/_GetBadgeCounts.gql';
import PaymentMethodIcon from '~/components/PaymentMethodIcon';
import Time from '~/components/Time';

import attestMutation from './_AttestRefund.gql';
import query from './_GetRefunds.gql';
import removeMutation from './_RemoveRefund.gql';

export default function AttestListResults({ params }) {
  const { paymentService, from, to, status } = params;

  const [error, setError] = useState('');
  const [attestAction] = useMutation(attestMutation, {
    onError: async error => {
      setError(error.message);
    },
    refetchQueries: [{ query: getOpenRefundsCount }, 'GetRefunds'],
  });
  const [removeAction] = useMutation(removeMutation, {
    onError: async error => {
      setError(error.message);
    },
    refetchQueries: [{ query: getOpenRefundsCount }, 'GetRefunds'],
  });

  const columns = useMemo(
    () => [
      {
        field: 'paymentMethod',
        width: 50,
        headerName: ' ',
        renderCell: data => <PaymentMethodIcon paymentMethod={data.value} />,
      },
      {
        field: 'created',
        flex: 1,
        headerName: 'Skapad',
        renderCell: data => data.value && <Time date={new Date(data.value)} />,
      },
      {
        field: 'user',
        flex: 1,
        headerName: 'Skapad av',
        renderCell: data => (
          <Link component={RouterLink} to={`/users/${data.value.id}`}>
            {data.value.name}
          </Link>
        ),
      },
      {
        field: 'amount',
        flex: 1,
        headerName: 'Belopp',
        valueFormatter: data => formatMoney(data.value),
      },
      {
        field: 'order',
        flex: 2,
        headerName: 'Order',
        renderCell: data => (
          <Link component={RouterLink} to={`/order/${data.value.id}`}>
            {data.value.label}
          </Link>
        ),
      },
      {
        field: 'attestants',
        flex: 1,
        headerName: 'Attestanter',
        renderCell: data =>
          data.value && <>{data.value.map(user => user.name).join(', ')}</>,
      },
      {
        field: 'status',
        width: 150,
        headerName: ' ',
        renderCell: data =>
          data?.value && (
            <>
              <IconButton
                color="primary"
                disabled={data.value === 'closed'}
                onClick={() =>
                  attestAction({
                    variables: {
                      id: data.row.id,
                    },
                  })
                }
              >
                <Check />
              </IconButton>
              <IconButton
                color="primary"
                disabled={data.value === 'closed'}
                onClick={() =>
                  removeAction({
                    variables: {
                      id: data.row.id,
                    },
                  })
                }
              >
                <Delete />
              </IconButton>
            </>
          ),
      },
    ],
    [attestAction, removeAction],
  );

  return (
    <>
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <DataGrid
        columns={columns}
        query={query}
        defaultSort="-created"
        filter={{
          paymentService,
          from,
          to,
          status,
        }}
      />
    </>
  );
}

AttestListResults.propTypes = {
  params: PropTypes.shape({
    paymentService: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};
