import { useQuery } from '@apollo/client';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { getYear } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { orderSearchStartYear } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import ProductSelect from '~/components/ReportProductSelect';
import SelectField from '~/components/Select';
import useQueryParams from '~/hooks/useQueryParams';

import getGraphKeyMetrics from './_GetGraphKeyMetrics.gql';
import IncomeChart from './IncomeChart';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  selectField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
}));

const initialValues = {
  year: getYear(new Date()).toString(),
};

export default function KeyMetricsList() {
  const classes = useStyles();
  const location = useLocation();

  const { state, handleChange, params } = useQueryParams(initialValues);
  const { error, data } = useQuery(getGraphKeyMetrics, {
    variables: {
      site: params.site,
      productIds: params.productIds,
      year: Number.parseInt(params.year),
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data) {
    return <CircularProgress />;
  }
  const yearOptions = [];
  for (let year = orderSearchStartYear; year <= getYear(new Date()); year++) {
    yearOptions.push({ value: year, label: year });
  }

  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <ProductSelect />
          <SelectField
            label="År"
            value={state.year.toString()}
            onChange={e => handleChange('year', e.target.value)}
            className={classes.selectField}
            options={yearOptions}
          />
        </Box>
      </Box>
      <Box>
        <IncomeChart
          productIds={state.productIds}
          graphLabel={`Totalintänkter för ${state.year}`}
          data={data.getGraphKeyMetrics}
          dataKey="totalIncome"
          dataLabel="Inkomster"
        />
        <IncomeChart
          productIds={state.productIds}
          graphLabel={`MRR för ${state.year}`}
          data={data.getGraphKeyMetrics}
          dataKey="mrr"
          dataLabel="MRR"
        />
        <IncomeChart
          productIds={state.productIds}
          graphLabel={`ARPU för ${state.year}`}
          data={data.getGraphKeyMetrics}
          dataKey="arpu"
          dataLabel="ARPU"
        />
        <Box display="flex" className={classes.box}>
          <Box ml="auto">
            <Button
              color="primary"
              variant="contained"
              startIcon={<GetApp />}
              component="a"
              target="_blank"
              // location.search ???
              href={`/api/export/graph_keymetrics${location.search}`}
              rel="noreferrer"
            >
              Nyckeltal
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
