import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import ProductAutocomplete from '~/components/ProductAutocomplete';
import { userPropType } from '~/propTypes';

import mutation from './_AddSubscriptionToPayee.gql';
import * as usersQuery from './_GetUsers.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = Yup.object().shape({
  product: Yup.object().required('En produkt måste anges.'),
  user: Yup.object().required('En användare måste anges.'),
});

export default function UserAddSubscription({ userData }) {
  const classes = useStyles();

  const payee = userData?.getUser;

  const [subscriptionActionError, setSubscriptionActionError] = useState('');
  const [missingAddressError, setMissingAddressError] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const refetchUser = useCallback(search => {
    setUserSearch(search);
    //refetch();
  }, []);

  const apollo = useApolloClient();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const history = useHistory();

  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery(usersQuery, { variables: { filter: { search: userSearch } } });

  if (usersError) {
    return <Alert severity="error">{usersError.message}</Alert>;
  }

  if (subscriptionActionError) {
    return <ErrorMessage error={subscriptionActionError} />;
  }

  const userList = usersData?.list?.items ?? [];

  return (
    <>
      <Breadcrumb name="Lägg till prenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Lägg till samlingsbetalad prenumeration
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Ange en produkt och en användare för prenumerationen.
            Prenumerationen kommer att börja gälla direkt och få giltighetstid
            till slutet av året.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Denna prenumeration kommer att samlingsbetalas av {payee.name}.
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          product: undefined,
          user: '',
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting }) => {
          const user = values.user;

          if (
            values.product.distributable === true &&
            user.address?.address == null
          ) {
            setMissingAddressError(
              'Eftersom detta är en pappersprenumeration måste kundens address vara ifylld!',
            );
          } else {
            const response = await action({
              variables: {
                payee: payee.id,
                userId: user.id,
                productId: values.product.id,
              },
            }).catch(responseError =>
              setSubscriptionActionError(responseError),
            );
            setSubmitting(false);

            if (response) {
              history.push(`/users/${payee.id}`);
            }
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, setFieldValue, errors }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <ProductAutocomplete filter={{ type: 'subscription' }} />
            <Field
              noOptionsText={
                usersLoading ? 'Laddar in användare...' : 'Inga träffar.'
              }
              name="user"
              component={Autocomplete}
              filterSelectedOptions
              options={userList}
              getOptionLabel={option => option.label}
              getOptionSelected={option => option.id}
              onInputChange={async (event, value) => {
                refetchUser(value);
              }}
              filterOptions={x => x} // forces refetch
              onChange={(_, value) => setFieldValue('user', value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Sök användare"
                  variant="outlined"
                  error={errors.user != null}
                />
              )}
            />
            {errors.user != null && (
              <Box mb={2}>
                <Alert severity="error">{errors.user}</Alert>
              </Box>
            )}
            {missingAddressError !== '' && (
              <Box mb={2}>
                <Alert severity="error">{missingAddressError}</Alert>
              </Box>
            )}
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserAddSubscription.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
