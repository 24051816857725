import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { formatMoney } from '@trmediaab/zebra-utils';

import NoData from '~/components/NoData';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { orderPropType } from '~/propTypes';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function OrderPayments({ order }) {
  const { payments, refundFor } = order;
  return (
    <TablePaper title={refundFor ? 'Återbetalningar' : 'Inbetalningar'}>
      {payments.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell>Belopp</TableCell>
                <TableCell>Källa</TableCell>
                <TableCell>Typ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map(
                item =>
                  item && (
                    <StyledTableRow key={item.id}>
                      <TableCell>
                        <Time
                          date={new Date(item.date)}
                          format="yyyy-MM-dd HH:mm"
                        />
                      </TableCell>
                      <TableCell>{formatMoney(item.amount)}</TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          color="primary"
                          label={
                            item.source.charAt(0).toUpperCase() +
                            item.source.slice(1)
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        {item.refund ? 'Återbetalning' : 'Inbetalning'}
                      </TableCell>
                    </StyledTableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoData text="Den här ordern saknar inbetalningar" />
      )}
    </TablePaper>
  );
}

OrderPayments.propTypes = {
  order: orderPropType,
};
