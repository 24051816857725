import { useMutation } from '@apollo/client';
import { Box, Button, Link, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import NoData from '~/components/NoData';
import TableList from '~/components/TableList';

import SendOrderBuilder from './_SendOrderBuilder.gql';

export default function FailedOrders({
  orderBuilder,
  failedOrderByUsers,
  status,
}) {
  const [action, { loading, error: sentError }] = useMutation(SendOrderBuilder);

  const sendOrderBuilder = useCallback(() => {
    action({
      variables: {
        id: orderBuilder,
        sendForFailedOrders: true,
      },
    });
  }, [action, orderBuilder]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = event => {
    setPage(event);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headers = ['Användar-id'];

  return (
    <>
      {sentError && (
        <Box mt={2}>
          <ErrorMessage error={sentError} />
        </Box>
      )}
      <TableList
        title="Misslyckade utskick"
        page={page + 1}
        data={{
          items: failedOrderByUsers,
          total: failedOrderByUsers.length,
        }}
        pageSize={rowsPerPage}
        headers={headers}
        updatePage={handleChangePage}
        updateRowSize={handleChangeRowsPerPage}
        button={
          <Box display="flex" justifyContent="flex-end">
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  failedOrderByUsers.length === 0 ||
                  loading ||
                  status !== 'complete'
                }
                onClick={sendOrderBuilder}
              >
                Försök igen
              </Button>
            </Box>
            {failedOrderByUsers.length > 0 && (
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  component="a"
                  target="_blank"
                  href={`/api/export/failed_orders/${orderBuilder}`}
                  rel="noreferrer"
                >
                  Exportera
                </Button>
              </Box>
            )}
          </Box>
        }
      >
        {failedOrderByUsers
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(user => (
            <TableRow key={user.id}>
              <TableCell>
                <Link component={RouterLink} to={`/users/${user.id}`}>
                  {user.name != null && user.name !== '' ? user.name : user.id}
                </Link>
              </TableCell>
            </TableRow>
          ))}

        {failedOrderByUsers.length === 0 && (
          <TableRow>
            <TableCell colSpan={headers.length}>
              <NoData text="Det finns inga misslyckade utskick" />
            </TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

FailedOrders.propTypes = {
  failedOrderByUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  orderBuilder: PropTypes.string,
  status: PropTypes.string,
};
