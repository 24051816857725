import {
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  deliveryMethods,
  giftStatus as giftStatusTypes,
  orderStatus as _orderStatus,
  paymentMethods,
  paymentStatus as _paymentStatus,
} from 'trhub-utils';

import Address from '~/components/Address';
import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { orderPropType } from '~/propTypes';

export default function OrderInformation({ order }) {
  const {
    id,
    user,
    paymentMethod,
    created,
    orderStatus,
    paymentStatus,
    paidDate,
    address,
    invoiceId,
    drupalId,
    deliveryMethod,
    ocr,
    refundFor,
    giftCode,
    giftExpiryDate,
    giftActivated,
    giftOrder,
    giftStatus,
  } = order;

  const orderTableRows = [
    ['Ordernummer', id],
    [
      'Orderdatum',
      <Time
        key={`${id}_created`}
        date={new Date(created)}
        format="yyyy-MM-dd HH:mm"
      />,
    ],
    [
      'Billecta ID',
      invoiceId ? (
        <Link
          href={`https://app.billecta.com/in/View/${invoiceId}`}
          target="_blank"
        >
          {invoiceId}
        </Link>
      ) : (
        ''
      ),
    ],
    ['OCR', ocr],
    [
      'Status',
      <Chip
        key={`${id}_status`}
        color="primary"
        label={_orderStatus[orderStatus]}
        size="small"
      />,
    ],
    [
      'Betalningsstatus',
      <Chip
        key={`${id}_paymentStatus`}
        color="primary"
        label={paymentStatus ? _paymentStatus[paymentStatus] : 'Info saknas'}
        size="small"
      />,
    ],
    [
      'Betalningsdatum',
      paidDate ? (
        <Time
          key={`${id}_paymentDate`}
          date={new Date(paidDate)}
          format="yyyy-MM-dd HH:mm"
        />
      ) : (
        ''
      ),
    ],
    [
      'Mottagare',
      <Link key={`${id}_user`} component={RouterLink} to={`/users/${user?.id}`}>
        {user
          ? user.name != null && user.name !== ''
            ? user.name
            : user.email
          : ''}
      </Link>,
    ],
    ['Mobilnummer', user?.mobile || <em>Uppgift saknas</em>],
    ['E-postadress', user?.email],
    ['Adress', <Address key={`${id}_address`} address={address} />],
  ];

  if (refundFor) {
    orderTableRows.unshift([
      'Krediterar',
      <Link key={refundFor} component={RouterLink} to={`/order/${refundFor}`}>
        {refundFor}
      </Link>,
    ]);
  }
  if (drupalId) {
    orderTableRows.push([
      'Drupal ID',
      <Link
        key={drupalId}
        href={`https://www.trmedia.se/admin/commerce/orders/${drupalId}`}
        target="_blank"
      >
        {drupalId}
      </Link>,
    ]);
  }

  if (deliveryMethod) {
    orderTableRows.push([
      'Leveransmetod',
      <Chip
        key={`${id}_deliveryMethod`}
        color="primary"
        label={deliveryMethods[deliveryMethod]}
        size="small"
      />,
    ]);
  }

  if (paymentMethod) {
    orderTableRows.push([
      'Betalningsmetod',
      <Chip
        key={`${id}_paymentMethod`}
        color="primary"
        label={paymentMethods[paymentMethod]}
        size="small"
      />,
    ]);
  }

  if (giftCode) {
    orderTableRows.push([
      'Gåvokod',
      <Chip
        key={`${id}_giftCode`}
        color="primary"
        label={giftCode}
        size="small"
      />,
    ]);

    if (giftStatus) {
      orderTableRows.push([
        'Gåvokodsstatus',
        <Chip
          key={`${id}_giftStatus`}
          color="primary"
          label={giftStatusTypes[giftStatus]}
          size="small"
        />,
      ]);
    }

    if (giftOrder) {
      orderTableRows.push([
        'Aktiverad gåvokodsorder',
        <Link
          key={`${id}_giftOrder`}
          component={RouterLink}
          to={`/order/${giftOrder.id}`}
        >
          {giftOrder.id}
        </Link>,
      ]);
    }

    if (giftExpiryDate) {
      orderTableRows.push([
        'Gåvokodens förfallodatum',
        giftExpiryDate ? (
          <Time
            key={`${id}_giftExpiryDate`}
            date={new Date(giftExpiryDate)}
            format="yyyy-MM-dd HH:mm"
          />
        ) : (
          ''
        ),
      ]);
    }

    if (giftActivated) {
      orderTableRows.push([
        'Gåvokodens aktiveringsdatum',
        <Time
          key={`${id}_giftActivated`}
          date={new Date(giftActivated)}
          format="yyyy-MM-dd HH:mm"
        />,
      ]);
    }
  }

  const title = refundFor ? 'Kreditorder' : 'Order';

  return (
    <TablePaper title={title}>
      <TableContainer>
        <Table>
          <TableBody>
            {orderTableRows.map(([label, value]) => (
              <TableRow key={label}>
                <TableCell
                  css={`
                    vertical-align: top;
                  `}
                >
                  {label}
                </TableCell>
                <TableCell
                  css={`
                    vertical-align: top;
                  `}
                  align="right"
                >
                  {value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TablePaper>
  );
}

OrderInformation.propTypes = {
  order: orderPropType,
};
