import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';

import deleteMutation from './_DeleteOauthClient.gql';
import query from './_GetOauthClients.gql';
import mutation from './_SaveOauthClient.gql';

const validationSchema = Yup.object().shape({
  clientId: Yup.string()
    .required('Client ID måste anges.')
    .matches(/^[_a-z-]+$/, {
      message:
        'Client ID får bara innehålla små bokstäver, understreck och bindestreck.',
    }),

  hosts: Yup.array().of(Yup.string()),
  allowProxy: Yup.boolean(),
  sessionLimit: Yup.number()
    .min(0, 'Måste vara ett positivt heltal.')
    .notRequired(),
});

export default function OauthForm() {
  const { clientId } = useParams();
  const router = useHistory();

  const { data, loading, error: queryError } = useQuery(query);
  const [action, { error: mutationError }] = useMutation(mutation, {
    refetchQueries: [{ query }],
  });

  const [deleteAction, { error: deleteMutationError, loading: isDeleting }] =
    useMutation(deleteMutation, {
      refetchQueries: [{ query }],
    });

  const initialValues = useMemo(
    () =>
      (data &&
        clientId &&
        data.items.find(item => item.clientId === clientId)) || {
        hosts: [''],
        allowProxy: false,
        sessionLimit: 0,
      },
    [clientId, data],
  );

  const onSubmit = useCallback(
    async values => {
      // Skip sending back the client secret
      const { clientSecret, nrOfSessions, ...input } = values;

      try {
        await action({
          variables: {
            input,
          },
        });

        router.push('/settings/oauth');
      } catch (error_) {
        console.error(error_);
      }
    },
    [action, router],
  );

  const deleteOauthClient = useCallback(async () => {
    try {
      await deleteAction({
        variables: {
          clientId,
        },
      });

      router.push('/settings/oauth');
    } catch (error_) {
      console.error(error_);
    }
  }, [clientId, deleteAction, router]);

  const error = queryError || mutationError || deleteMutationError;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb name={clientId || 'Add new'} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form
            css={{
              '.MuiFormControl-root': {
                width: '100%',
              },
            }}
          >
            <Paper
              css={({ theme }) => ({
                padding: theme.spacing(3),
                '> * + *': {
                  marginTop: theme.spacing(4),
                },
              })}
            >
              <Field
                component={TextField}
                name="clientId"
                label="Client ID"
                variant="outlined"
                helperText="Måste vara i små bokstäver, understreck och bindestreck och får inte innehålla mellanslag."
              />
              <Field
                component={TextField}
                name="clientSecret"
                label="Client Secret"
                variant="outlined"
                disabled
                helperText="Genereras automatiskt."
              />
              <Field
                component={CheckboxWithLabel}
                name="allowProxy"
                Label={{ label: 'Tillåt proxy' }}
                type="checkbox"
                checked={values.allowProxy || false}
                value={values.allowProxy || false}
                variant="outlined"
              />
              <Field
                component={TextField}
                name="sessionLimit"
                label="Session limit"
                variant="outlined"
                type="number"
                helperText="Antal tillåtna samtidiga sessioner, lämna tomt eller 0 för obegränsat."
              />
              <FieldArray
                name="hosts"
                render={arrayHelpers => (
                  <Box
                    css={{
                      '> * + *': {
                        marginTop: '10px',
                      },
                    }}
                  >
                    {values.hosts.map((host, index) => (
                      <Box key={index} display="flex" alignItems="flex-start">
                        <Field
                          flex={1}
                          component={TextField}
                          name={`hosts.${index}`}
                          label="Redirect hostname"
                          variant="outlined"
                          helperText="Vitlistat hostsname för redirect efter inloggning. Valideras bara i prod."
                        />
                        <Box ml="10px ">
                          <IconButton
                            onClick={() => arrayHelpers.remove(index)}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => arrayHelpers.push(null)}
                    >
                      Lägg till hostname
                    </Button>
                  </Box>
                )}
              />

              {error && <ErrorMessage error={error} />}

              <Button
                onClick={submitForm}
                loading={isSubmitting}
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                Spara
              </Button>

              {clientId && (
                <Button
                  onClick={deleteOauthClient}
                  loading={isDeleting}
                  disabled={isDeleting}
                  variant="contained"
                  color="danger"
                  css={() => ({
                    marginLeft: '10px',
                  })}
                >
                  Radera
                </Button>
              )}
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  );
}
