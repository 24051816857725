import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { orderBuilderPropType } from '~/propTypes';

import mutation from './_EditOrderBuilder.gql';
import OrderBuilderForm from './OrderBuilderForm';

export default function OrderBuilderEdit({ orderBuilderData }) {
  const [error, setError] = useState('');

  const [action] = useMutation(mutation);
  const history = useHistory();

  const { id, price, name, product } = orderBuilderData.getOrderBuilder;

  return (
    <>
      <Breadcrumb name="Redigera" />
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <OrderBuilderForm
        initialValues={{
          name: name,
          product: product,
          price: Math.round(price / 100),
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              id,
              input: {
                ...values,
                product:
                  product.id === values.product.id
                    ? product.id
                    : values.product.id,
                price: Number(values.price) * 100,
              },
            },
          }).catch(responseError => setError(responseError.message));
          setSubmitting(false);

          if (response) {
            history.push(`/order-builders/${id}`);
          }
        }}
      />
    </>
  );
}

OrderBuilderEdit.propTypes = {
  orderBuilderData: PropTypes.shape({
    getOrderBuilder: orderBuilderPropType,
  }).isRequired,
};
