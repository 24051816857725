import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PictureAsPdf } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createOrderFromAdminProducts } from 'trhub-utils';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import CampaignAutocomplete from '~/components/CampaignAutocomplete';
import ErrorMessage from '~/components/ErrorMessage';
import ProductAutocomplete from '~/components/ProductAutocomplete';
import { userPropType } from '~/propTypes';

import mutation from './_CreateUserOrder.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = Yup.object().shape({
  product: Yup.object().required('En produkt måste anges.'),
  deliveryMethod: Yup.string().required('En lanseringsmetod måste anges.'),
});

export default function UserAddOrder({ userData }) {
  const classes = useStyles();

  const user = userData?.getUser;

  const [actionError, setActionError] = useState('');

  const apollo = useApolloClient();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const history = useHistory();

  if (actionError) {
    return <ErrorMessage error={actionError} />;
  }

  //TODO: Add send by e-mail option once discussed in #1812
  return (
    <>
      <Breadcrumb name="Skapa order" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Skicka avi
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Eventuell Kampanj som ska användas för denna prenumeration skapas
            innan. Väljs ingen kampanj gäller ordinarie pris på paketet.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Om det redan finns en prenumerationen på kundens konto med samma
            produkt så kommer ordern att bli automatiskt kopplad till den.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Säkerställ att namn, adress och e-post finns med på kundens konto.
          </Typography>
        </CardContent>
      </Card>
      <Formik
        initialValues={{
          product: undefined,
          campaign: undefined,
          deliveryMethod: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              user: user.id,
              product: values.product.id,
              campaign: values.campaign?.id,
              deliveryMethod: values.deliveryMethod,
            },
          }).catch(responseError => setActionError(responseError));
          setSubmitting(false);

          if (response) {
            history.push(`/users/${user.id}`);
          }
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <ProductAutocomplete
              filter={{ skus: createOrderFromAdminProducts }}
            />
            <CampaignAutocomplete
              filter={
                values.product
                  ? {
                      products: [values.product.id],
                    }
                  : null
              }
            />
            <Field
              label="Leveransmetod"
              component={TextField}
              select
              name="deliveryMethod"
              variant="outlined"
            >
              <MenuItem key="brev" value="mail">
                Brev
              </MenuItem>
              <MenuItem key="manually" value="manually">
                Manuellt (skickas inte)
              </MenuItem>
            </Field>

            {isSubmitting && <LinearProgress />}
            <Box mt={2} display="flex">
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<PictureAsPdf />}
                  component="a"
                  target="_blank"
                  href={`/api/preview_notification?user=${user.id}&product=${
                    values.product?.id ?? ''
                  }&deliveryMethod=${values.deliveryMethod ?? ''}&campaign=${
                    values.campaign?.id ?? ''
                  }`}
                  rel="noreferrer"
                >
                  Förhandsgranska
                </Button>
              </Box>
              <Box ml="auto">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Skicka avi
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserAddOrder.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
