import { Avatar, Box, IconButton, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import DataGrid from '~/components/DataGrid';

import query from './_GetUsers.gql';

const columns = [
  {
    field: 'id',
    flex: 3,
    headerName: 'Användare',
    sortable: false,
    renderCell: params => (
      <Box alignItems="center" display="flex" fontSize="small">
        <IconButton
          aria-label="Avatar"
          component={RouterLink}
          to={`/users/${params.row.id}`}
        >
          <Avatar src={params.row.avatarUrl}>{params.row.initials}</Avatar>
        </IconButton>
        <Box ml={2}>
          <Link component={RouterLink} to={`/users/${params.row.id}`}>
            {params.row.label}
          </Link>
        </Box>
      </Box>
    ),
  },
  {
    field: 'jokerProfile',
    flex: 2,
    headerName: 'Joker alias',
    renderCell: params =>
      params.value ? (
        <Box lineHeight="normal" fontSize="12px">
          {params.value.alias}
        </Box>
      ) : (
        ''
      ),
  },
  {
    field: 'email',
    flex: 2,
    headerName: 'Epost',
  },
  {
    field: 'customerNumber',
    flex: 1,
    headerName: 'Kundnummer',
  },
  {
    field: 'address',
    flex: 2,
    headerName: 'Adress',
    renderCell: params =>
      params.value.address ? (
        <Box lineHeight="normal" fontSize="12px">
          {params.value.address}
          <br />
          {params.value.postalCode} {params.value.city}
        </Box>
      ) : (
        ''
      ),
  },
];

export default function UserListResults({
  selectedRows,
  handleSelectRows,
  params,
}) {
  const { role, search, onlyCollectionPayee, card, jokerAlias } = params;
  return (
    <DataGrid
      checkboxSelection
      columns={columns}
      query={query}
      defaultSort="-lastAccess"
      filter={{
        role,
        search: typeof search === 'number' ? search.toString() : search,
        onlyCollectionPayee,
        card: card.toString() > 0 ? card.toString() : '',
        jokerAlias,
      }}
      selectionModel={selectedRows}
      onSelectionModelChange={rows => {
        handleSelectRows(rows);
      }}
    />
  );
}

UserListResults.propTypes = {
  params: PropTypes.shape({
    role: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    onlyCollectionPayee: PropTypes.bool.isRequired,
    card: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    jokerAlias: PropTypes.string.isRequired,
  }).isRequired,
  selectedRows: PropTypes.array.isRequired,
  handleSelectRows: PropTypes.func.isRequired,
};
