import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';

import mutation from './_ChangeDeliveryMethodSubscription.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

export default function SubscriptionChangeDeliveryMethod() {
  const classes = useStyles();

  const apollo = useApolloClient();

  const { subscription } = useParams();

  const [actionError, setActionError] = useState('');

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const history = useHistory();

  if (actionError) {
    return <ErrorMessage error={actionError} />;
  }

  return (
    <>
      <Breadcrumb name="Förläng prenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Ändra leveransmetod
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(3)}px;
            `}
          >
            Leveransmetod påverkar hur avier/fakturor skickas ut till kunden.
            Vid val av e-post kommer även en fysisk faktura att skickas ut 15
            dagar innan förfallodatumet om kunden INTE har läst
            e-postmeddelandet och givet att kunden har en address registrerad.
          </Typography>
        </CardContent>
      </Card>
      <Formik
        initialValues={{
          deliveryMethod: 'mail',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              id: subscription,
              deliveryMethod: values.deliveryMethod,
            },
          }).catch(responseError => setActionError(responseError));
          setSubmitting(false);

          if (response) {
            history.push(`/subscriptions/${subscription}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <Field
              label="Leveransmetod"
              component={TextField}
              select
              name="deliveryMethod"
              variant="outlined"
            >
              <MenuItem key="brev" value="mail">
                Brev
              </MenuItem>
              <MenuItem key="e-post" value="email">
                E-post
              </MenuItem>
            </Field>
            {isSubmitting && <LinearProgress />}
            <Box mt={2} display="flex">
              <Box ml="auto">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Spara
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
