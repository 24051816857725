import { useApolloClient, useMutation } from '@apollo/client';
import { Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';

import mutation from './_ResetPassword.gql';

export default function ResetPassword({ userId, email }) {
  const apollo = useApolloClient();
  const history = useHistory();

  const [error, setError] = useState('');

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const handleResetPassword = useCallback(async () => {
    const response = await action({
      variables: {
        id: userId,
      },
    }).catch(responseError => setError(responseError));

    if (response) {
      history.push(`/users/${userId}`);
    }

    setOpen(false);
  }, [action, history, userId]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        css={({ theme }) => ({
          backgroundColor: theme.palette.info.main,
          color: theme.palette.text.main,
          '&:hover': {
            background: theme.palette.info.dark,
          },
        })}
        onClick={handleClickOpen}
        variant="contained"
      >
        Återställ lösenord
      </Button>
      {error ? (
        <Box
          css={({ theme }) => ({
            marginTop: theme.spacing(2),
          })}
        >
          <ErrorMessage error={error} />
        </Box>
      ) : (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Återställ lösenord</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ett mail med återställningslänk kommer skickas till {email}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Avbryt
            </Button>
            <Button
              onClick={handleResetPassword}
              css={({ theme }) => ({
                color: theme.palette.error.main,
              })}
            >
              Skicka återställningslänk
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

ResetPassword.propTypes = {
  userId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
