import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '240px',
  },
}));
export default function IncomeChart({ graphLabel, data, dataKey, dataLabel }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {graphLabel}
      </Typography>

      <ResponsiveContainer>
        <LineChart data={data}>
          <Line
            type="monotone"
            dataKey={dataKey}
            name={dataLabel}
            stroke="#F55B39"
          />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip formatter={value => `${value.toLocaleString()} kr`} />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
}

IncomeChart.propTypes = {
  graphLabel: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
};
