import { useApolloClient, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';

import addProductMutation from './_AddProduct.gql';
import ProductForm from './ProductForm';

export default function ProductAdd() {
  const apollo = useApolloClient();
  const [error, setError] = useState('');

  const [action] = useMutation(addProductMutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Lägg till ny" />
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <ProductForm
        initialValues={{
          organization: 'trmedia_ab',
          name: '',
          type: '',
          sku: '',
          price: 100,
          vat: 25,
          site: '',
          products: [],
          distributable: false,
          allowedPaymentMethods: [],
          roundDate: format(new Date(), 'yyyy-MM-dd'),
          roundId: '',
          paymentInterval: '',
          betType: [],
          punches: 0,
          costCenter: '',
          project: '',
          promoted: false,
          isGiftProduct: false,
          presentation: {
            headline: '',
            infoText: '',
            buttonText: '',
            paymentTerms: '',
            bullets: [],
          },
          redeemPresentation: {
            headline: '',
            infoText: '',
            buttonText: '',
            paymentTerms: '',
          },
        }}
        addProduct
        onSubmit={async (values, { setSubmitting }) => {
          const {
            paymentInterval,
            roundDate,
            price,
            type,
            roundId,
            costCenter,
            project,
            punches,
            purchasable = false,
            ...rest
          } = values;
          const modifiedValues = {
            ...rest,
            type,
            price: Number(price) * 100,
            paymentInterval: paymentInterval
              ? Number.parseInt(paymentInterval, 10)
              : undefined,
            roundDate: type === 'bettip' ? roundDate : null,
            roundId: type === 'bettip' ? Number.parseInt(roundId, 10) : null,
            costCenter: costCenter ? Number.parseInt(costCenter, 10) : null,
            project: project ? Number.parseInt(project, 10) : null,
            punches: Number(punches),
            purchasable,
          };

          const response = await action({
            variables: {
              input: modifiedValues,
            },
          }).catch(responseError => setError(responseError.message));
          setSubmitting(false);

          if (response) {
            history.push(`/products/${response.data.addProduct.id}`);
          }
        }}
      />
    </>
  );
}
