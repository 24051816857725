import { MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const Select = ({ options, multiselect = false, value, ...props }) => (
  <TextField
    variant="outlined"
    select
    {...props}
    SelectProps={{
      multiple: multiselect,
      value: multiselect ? value || [] : value,
      renderValue: selected => {
        if (multiselect) {
          const selectedOptions = options.filter(option =>
            selected.includes(option.value),
          );
          return selectedOptions.map(option => option.label).join(', ');
        } else {
          const selectedOption = options.find(
            option => option.value === selected,
          );
          return selectedOption ? selectedOption.label : '';
        }
      },
    }}
  >
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        <em>{option.label}</em>
      </MenuItem>
    ))}
  </TextField>
);

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  multiselect: PropTypes.bool,
};

export default Select;
