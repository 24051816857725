import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import OrderBuilderListResults from './OrderBuilderListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

const initialValues = {
  search: '',
};

export default function OrderList() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { isAdmin } = useAuthUser();

  const { params, state, clear, handleChange } = useQueryParams(initialValues);

  return (
    <>
      <Box
        maxWidth="100%"
        display="flex"
        flexWrap="wrap"
        gridAutoFlow="column"
        gridColumnGap="10px"
        marginTop="-10px"
        marginBottom={2}
      >
        <TextField
          className={classes.textField}
          label="Sök"
          variant="outlined"
          value={state.search.toString()}
          onChange={e => handleChange('search', e.target.value)}
        />

        {!shallowEqual(initialValues, state) && (
          <Button
            color="primary"
            css={() => ({
              textTransform: 'none',
            })}
            onClick={clear}
          >
            <Box fontWeight="fontWeightBold">Nollställ filter</Box>
          </Button>
        )}
      </Box>

      <Box mb={2} display="flex" justifyContent="space-between">
        {isAdmin && (
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        )}
      </Box>

      <Box>
        <OrderBuilderListResults params={params} />
      </Box>
    </>
  );
}
