import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import ProductAutocomplete from '~/components/ProductAutocomplete';
import { userPropType } from '~/propTypes';

import mutation from './_GiveTicketToUser.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = Yup.object().shape({
  product: Yup.object().required('En produkt måste anges.'),
  punches: Yup.number().required('Antal klipp måste anges.'),
});

export default function UserGiveTicket({ userData }) {
  const classes = useStyles();

  const user = userData?.getUser;

  const [ticketActionError, setTicketActionError] = useState('');

  const apollo = useApolloClient();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const history = useHistory();

  if (ticketActionError) {
    return <ErrorMessage error={ticketActionError} />;
  }

  return (
    <>
      <Breadcrumb name="Ge gratis klippkort" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Ge gratis klippkort
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Ange en klippkortsprodukt och ett antal klipp. Klippkortet kommer
            att börja gälla direkt.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Detta &quot;klippkortsköp&quot; kommer inte att bli kopplad till
            någon order.
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          product: undefined,
          punches: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              userId: user.id,
              productId: values.product.id,
              punches: values.punches,
            },
          }).catch(responseError => setTicketActionError(responseError));
          setSubmitting(false);

          if (response) {
            history.push(`/users/${user.id}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <ProductAutocomplete filter={{ type: 'ticket' }} />
            <Field
              component={TextField}
              name="punches"
              label="Antal klipp"
              type="number"
              variant="outlined"
            />
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserGiveTicket.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
