import { List, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';

const Events = ({ items }) => (
  <TablePaper title="Logg">
    <List css={{ maxHeight: '800px', overflowY: 'auto' }}>
      {[...items].reverse().map((item, i, array) => (
        <ListItem key={i} disableGutters divider={i < array.length - 1}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              color: 'textSecondary',
            }}
            secondaryTypographyProps={{
              variant: 'body2',
              color: 'textPrimary',
            }}
            primary={
              <Time date={new Date(item.timestamp)} format="yyyy-MM-dd HH:mm" />
            }
            secondary={
              <>
                {item.user && (
                  <>
                    <Link to={`/users/${item.user.id}`}>{item.user.name}</Link>
                    {' | '}
                  </>
                )}
                {item.message}{' '}
                {item.order && <Link to={`/order/${item.order}`}>Order</Link>}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  </TablePaper>
);

Events.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      order: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
  ),
};

export default Events;
