import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import OrderList from '~/components/OrderList';
import { userPropType } from '~/propTypes';

import Auctions from './Auctions';
import Bettips from './Bettips';
import Cards from './Cards';
import Distributions from './Distributions';
import Events from './Events';
import Profile from './Profile';
import Subscriptions from './Subscriptions';
import Tickets from './Tickets';
import WatcherSettings from './WatcherSettings';

export default function UserDetail({ userData }) {
  const { id, collectionPayee, eventLog } = userData.getUser;
  const { pathname } = useLocation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Profile userData={userData.getUser} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Events items={eventLog} user={id} />
      </Grid>
      <Grid item xs={12}>
        <OrderList
          filter={{
            userId: id,
          }}
          button={
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={`${pathname}/order/add`}
            >
              Skapa order
            </Button>
          }
        />
      </Grid>
      {collectionPayee ? (
        <Grid item xs={12}>
          <Subscriptions payee={id} collectionPayee />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Subscriptions user={id} />
          </Grid>
          <Grid item xs={12}>
            <Subscriptions payee={id} />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Cards user={id} organization="trmedia_ab" />
      </Grid>
      <Grid item xs={12}>
        <Cards user={id} organization="jokersystemet_as" />
      </Grid>
      <Grid item xs={12}>
        <Distributions user={id} />
      </Grid>
      <Grid item xs={12}>
        <Auctions userData={userData.getUser} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Bettips user={id} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Tickets user={id} />
      </Grid>
      <Grid item xs={12}>
        <WatcherSettings userData={userData.getUser} />
      </Grid>
    </Grid>
  );
}

UserDetail.propTypes = {
  userData: PropTypes.shape({
    getUser: userPropType,
  }).isRequired,
};
