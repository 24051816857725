import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetSubscriptionExpiryReports.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function SubscriptionExpiringListResult({ params }) {
  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);
  const { error, data } = useQuery(query, {
    variables: {
      filter: {
        productIds: params.productIds,
        from: params.from || undefined,
        to: params.to || undefined,
      },
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getSubscriptionExpiryReports;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = ['Datum', 'Antal'];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.subscriptionExpiryReports.length > 0 ? (
          res.subscriptionExpiryReports.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell className={classes.tableCellDate}>
                <Time date={new Date(row._id)} format="yyyy-MM-dd" />
              </TableCell>
              <TableCell>{row.amount}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5} align="center">
              Inga resultat finns för denna filtrering. Prova att ange en annan
              produkt, eller ett annat datumintervall.
            </TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

SubscriptionExpiringListResult.propTypes = {
  params: PropTypes.shape({
    productIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};
