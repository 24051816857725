import 'react-markdown-editor-lite/lib/index.css';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Editor, { Plugins } from 'react-markdown-editor-lite';
import { markdownParser } from 'trhub-utils';

import Mark from './MarkPlugin';

// Fullscreen is not usable because the editor renders below the top
// AppBar and left Drawer
Editor.unuse(Plugins.FullScreen);
// Default enable Mark plugin
Editor.use(Mark);

const useStyles = makeStyles({
  root: {
    marginTop: 8,
    '& .button-type-underline': {
      display: 'none',
    },
  },
});

const TextEditor = ({ value, height, menuVariant = 'default', onChange }) => {
  const classes = useStyles();

  return (
    <Editor
      className={classes.root}
      style={{ height: `${height}px` }}
      value={value}
      renderHTML={text => markdownParser(text)}
      plugins={
        menuVariant === 'reduced'
          ? [
              'font-bold',
              'font-italic',
              'font-strikethrough',
              'font-mark',
              'link',
              'clear',
              'logger',
              'mode-toggle',
            ]
          : undefined
      }
      onChange={onChange}
    />
  );
};

TextEditor.propTypes = {
  value: PropTypes.string,
  menuVariant: PropTypes.oneOf(['reduced', 'default']),
  height: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextEditor;
