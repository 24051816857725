import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'clipboard-copy';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

export default function CopyToClipboard({ value, ...tooltipProps }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const onCopy = useCallback(() => {
    copy(value);
    setShowTooltip(true);
  }, [value]);

  const handleOnTooltipClose = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <Tooltip
      open={showTooltip}
      title="Kopierat till urklipp"
      leaveDelay={1500}
      onClose={handleOnTooltipClose}
      {...tooltipProps}
    >
      <Button size="small" onClick={onCopy}>
        Kopiera
      </Button>
    </Tooltip>
  );
}

CopyToClipboard.propTypes = {
  value: PropTypes.string.isRequired,
};
