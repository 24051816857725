import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { orderStatus, paymentStatus } from 'trhub-utils';

import SelectField from '~/components/Select';
import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import OrderListResults from './OrderListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

const initialValues = {
  productSku: '',
  status: '',
  user: '',
  search: '',
  paymentStatus: '',
};

export default function OrderList() {
  const classes = useStyles();

  const { params, state, clear, handleChange } = useQueryParams(initialValues);

  return (
    <>
      <Box
        maxWidth="100%"
        display="flex"
        flexWrap="wrap"
        gridAutoFlow="column"
        gridColumnGap="10px"
        marginTop="-10px"
        marginBottom={2}
      >
        <TextField
          className={classes.textField}
          label="Sök"
          variant="outlined"
          value={state.search.toString()}
          onChange={e => handleChange('search', e.target.value)}
        />

        <SelectField
          className={classes.textField}
          label="Betalstatus"
          value={state.paymentStatus}
          onChange={e => handleChange('paymentStatus', e.target.value)}
          options={[
            { value: '', label: 'Alla' },
            ...Object.entries(paymentStatus).map(([value, label]) => ({
              value,
              label,
            })),
          ]}
        />

        <SelectField
          className={classes.textField}
          label="Status"
          value={state.paymentStatus}
          onChange={e => handleChange('status', e.target.value)}
          options={[
            { value: '', label: 'Alla' },
            ...Object.entries(orderStatus).map(([value, label]) => ({
              value,
              label,
            })),
          ]}
        />

        <TextField
          className={classes.textField}
          label="Användare"
          variant="outlined"
          value={state.user}
          onChange={e => handleChange('user', e.target.value)}
        />

        <TextField
          className={classes.textField}
          label="Produkt SKU"
          variant="outlined"
          value={state.productSku}
          onChange={e => handleChange('productSku', e.target.value)}
        />

        {!shallowEqual(initialValues, state) && (
          <Button
            color="primary"
            css={() => ({
              textTransform: 'none',
            })}
            onClick={clear}
          >
            <Box fontWeight="fontWeightBold">Nollställ filter</Box>
          </Button>
        )}
      </Box>

      <Box>
        <OrderListResults params={params} />
      </Box>
    </>
  );
}
