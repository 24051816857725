import { useQuery } from '@apollo/client';
import {
  Avatar,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Check, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import NoData from '~/components/NoData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';

import query from './_GetCardsByUser.gql';

export default function Cards({ user, organization }) {
  const { error, loading, data } = useQuery(query, {
    variables: {
      user,
    },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Kort', 'Typ', 'Förvalt', 'Utgångsdatum'];

  const cards =
    data?.cards
      .filter(card => card.organization === organization)
      .sort((a, b) => b.isDefault - a.isDefault) || [];

  return (
    <TableList
      title={`Betalkort ${
        organization === 'trmedia_ab' ? 'TR Media' : 'Jokersystemet Norge'
      }`}
      data={{ total: cards.length }}
      headers={headers}
    >
      {cards.length > 0 ? (
        cards.map(card => (
          <TableRow key={card.id}>
            <TableCell>{card.maskedPan}</TableCell>
            <TableCell>{card.brand.toUpperCase()}</TableCell>
            <TableCell>
              <Avatar
                css={{
                  background: card.isDefault ? green[500] : undefined,
                  height: '16px',
                  width: '16px',
                  svg: {
                    fontSize: '12px',
                  },
                }}
              >
                {card.isDefault ? <Check /> : <Close />}
              </Avatar>
            </TableCell>
            <TableCell>
              <Time date={new Date(card.expiryDate)} format="yyyy-MM" />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={headers.length}>
            <NoData text="Den här användaren har inte några betalkort" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Cards.propTypes = {
  user: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
};
