import { Box, Button, Grid, LinearProgress, Paper } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { orderBuilderProducts } from 'trhub-utils';
import * as Yup from 'yup';

import { NumberField } from '~/components/NumberField';
import ProductAutocomplete from '~/components/ProductAutocomplete';
import { orderBuilderPropType } from '~/propTypes';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ett namn måste anges.'),
  price: Yup.number()
    .required('Ett belopp måste anges.')
    .moreThan(-1, 'Beloppet måste vara noll eller positivt heltal.'),
});

//DELETE csv and pdf if not saved?
export default function OrderBuilderForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting, values }) => (
        <Form
          css={{
            '.MuiFormControl-root': {
              width: '100%',
            },
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Paper
                css={({ theme }) => ({
                  padding: theme.spacing(3),
                  '> * + *': {
                    marginTop: theme.spacing(2),
                  },
                })}
              >
                <Field
                  component={TextField}
                  name="name"
                  label="Namn"
                  variant="outlined"
                />

                <ProductAutocomplete
                  filter={{ skus: orderBuilderProducts }}
                  defaultValue={
                    initialValues.product?.id
                      ? {
                          name: initialValues.product.name,
                          id: initialValues.product.id,
                        }
                      : null
                  }
                />
                <NumberField name="price" label={'Pris'} value={values.price} />
              </Paper>
              <Box
                css={({ theme }) => ({
                  marginTop: theme.spacing(2),
                })}
              >
                {isSubmitting && <LinearProgress />}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Spara
                </Button>
              </Box>
            </Grid>

            <Grid item></Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

OrderBuilderForm.propTypes = {
  initialValues: orderBuilderPropType,
  onSubmit: PropTypes.func.isRequired,
};
