import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { watcher } from 'trhub-utils';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { userPropType } from '~/propTypes';
import toOptions from '~/utils/toOptions';

import mutation from './_EditUser.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

function TabPanel({ value, data, tabKey, index, ...other }) {
  const { values } = useFormikContext();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Table>
          <TableBody>
            <TableRow key={'methods'}>
              <TableCell component="th" scope="row">
                {'Hur vill du bli meddelad?'}
              </TableCell>
              <TableCell align="right">
                <Field
                  component={TextField}
                  name={`${tabKey}.methods`}
                  value={values[tabKey].methods || []}
                  variant="outlined"
                  select
                  SelectProps={{
                    multiple: true,
                  }}
                >
                  {toOptions(watcher.methods).map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            </TableRow>
            {data.when && (
              <TableRow key={'when'}>
                <TableCell component="th" scope="row">
                  {'När vill du bli meddelad?'}
                </TableCell>
                <TableCell align="right">
                  <Field
                    component={TextField}
                    name={`${tabKey}.when`}
                    value={values[tabKey].when || []}
                    variant="outlined"
                    select
                    SelectProps={{
                      multiple: true,
                    }}
                  >
                    {toOptions(watcher.when).map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Field>
                </TableCell>
              </TableRow>
            )}
            {data.sorting && (
              <>
                <TableRow key={'sorting-start'}>
                  <TableCell component="th" scope="row">
                    {'Sortering av startanmälda hästar'}
                  </TableCell>
                  <TableCell align="right">
                    <Field
                      component={TextField}
                      name={`${tabKey}.sorting.start`}
                      value={values[tabKey].sorting.start || ''}
                      variant="outlined"
                      select
                    >
                      {toOptions(watcher.sort).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Field>
                  </TableCell>
                </TableRow>
                <TableRow key={'sorting-watch'}>
                  <TableCell component="th" scope="row">
                    {'Sortering av bevakade hästar'}
                  </TableCell>
                  <TableCell align="right">
                    <Field
                      component={TextField}
                      name={`${tabKey}.sorting.watch`}
                      value={values[tabKey].sorting.watch || ''}
                      variant="outlined"
                      select
                    >
                      {toOptions(watcher.sort).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </Field>
                  </TableCell>
                </TableRow>
              </>
            )}
            {data.betTypes && (
              <TableRow key={'betTypes'}>
                <TableCell component="th" scope="row">
                  {'Speltyper'}
                </TableCell>
                <TableCell align="right">
                  <Field
                    component={TextField}
                    name={`${tabKey}.betTypes`}
                    value={values[tabKey].betTypes || []}
                    variant="outlined"
                    select
                    SelectProps={{
                      multiple: true,
                    }}
                  >
                    {watcher.betTypes.map(betType => (
                      <MenuItem key={betType} value={betType}>
                        {betType}
                      </MenuItem>
                    ))}
                  </Field>
                  <div>
                    {data.betTypes.map(key => (
                      <Chip key={key} label={key} />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  tabKey: PropTypes.string.isRequired,
};
export default function UserEditWatcherSettings({ user }) {
  const classes = useStyles();

  const watcherSettings = user.watcherSettings;

  const apollo = useApolloClient();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const [tab, setTab] = useState(0);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Redigera bevakningsinställningar" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Bevakningsinställningar
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={watcherSettings}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              id: user.id,
              input: { watcherSettings: values },
            },
          });
          setSubmitting(false);

          if (response) {
            history.push(`/users/${user.id}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Hästar" />
              <Tab label="Spelform" />
              <Tab label="Jackpot" />
              <Tab label="Nyheter" />
              <Tab label="Omgångar" />
              <Tab label="Ämnen" />
              <Tab label="Skribenter" />
            </Tabs>
            {[
              'horse',
              'betType',
              'jackpot',
              'news',
              'meets',
              'topic',
              'author',
            ].map((key, index) => (
              <TabPanel
                key={key}
                value={tab}
                index={index}
                data={watcherSettings[key]}
                tabKey={key}
              />
            ))}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Spara
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

UserEditWatcherSettings.propTypes = {
  user: userPropType.isRequired,
};
