import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { formatMoney, formatNumber } from '@trmediaab/zebra-utils';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';

const TransformDate = ({ date }) => {
  const month = format(new Date(date), 'LLLL', { locale: sv });
  return <>{month.charAt(0).toUpperCase() + month.slice(1)}</>;
};

export default function MossListResults({
  data,
  error,
  loading,
  updatePage,
  updateRowSize,
}) {
  const res = data?.getMossReport;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res || loading) {
    return <CircularProgress />;
  }

  const headers = [
    'Land',
    'Momsprocent',
    'Förs. ex moms',
    'Moms',
    'Förs. ink moms',
    'Eurokurs',
    'Ex moms',
    'Moms',
  ];

  return (
    <TableList
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.countries.length > 0 ? (
        res.countries.map((country, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell>{country.name}</TableCell>
              <TableCell>{country.vatPercentage}%</TableCell>
              <TableCell>{formatMoney(country.salesExclVat)}</TableCell>
              <TableCell>{formatMoney(country.vatTotal)}</TableCell>
              <TableCell>{formatMoney(country.salesInclVat)}</TableCell>
              <TableCell>
                {formatNumber(country.euroExchangeRate, {
                  minimumFractionDigits: 4,
                  maximumFractionDigits: 4,
                })}
              </TableCell>
              <TableCell>
                €
                {formatNumber(country.salesExclVatInEuro / 100, {
                  minimumFractionDigits: 2,
                })}
              </TableCell>
              <TableCell>
                €
                {formatNumber(country.vatTotalInEuro / 100, {
                  minimumFractionDigits: 2,
                })}
              </TableCell>
            </TableRow>
          </Fragment>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={10} align="center">
            Inget rapportdata finns för denna filtrering. Prova ett annat
            datumintervall.
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

MossListResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.object,
  updatePage: PropTypes.func.isRequired,
  updateRowSize: PropTypes.func.isRequired,
  params: PropTypes.shape({
    month: PropTypes.string.isRequired,
  }).isRequired,
};

TransformDate.propTypes = {
  date: PropTypes.string.isRequired,
};
