import { useQuery } from '@apollo/client';
import { CircularProgress, Link, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { paymentMethods, unSubscribeReasons } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetCanceledSubscriptionsReport.gql';

const getReasonText = row => {
  const { reason, text } = row.unsubscribeReason;
  if (reason === 'other') {
    return `Annat: ${text}`;
  }
  if (reason) {
    return unSubscribeReasons[reason];
  }
  if (row.paymentMethod === 'card') {
    const [lastItem] = row.eventLog.slice(-1);
    return lastItem?.message;
  }
};

export default function CanceledSubscriptionsListResult({ params }) {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(30);

  const { paymentMethod, sites, inactiveUsersOnly, fromEndDate, toEndDate } =
    params;

  const { error, data } = useQuery(query, {
    variables: {
      page: page,
      limit: pageSize,
      filter: {
        sites,
        active: false,
        paymentMethod: paymentMethod === 'all' ? undefined : paymentMethod,
        inactiveUsersOnly,
        fromEndDate,
        toEndDate,
      },
    },
  });

  const res = data?.list;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  return (
    <TableList
      page={page}
      pageSize={pageSize}
      data={res}
      headers={[
        'Prenumeration',
        'Betalmetod',
        'Startad',
        'Avslutad',
        'Orsak',
        'Telefonnummer',
        'Mailadress',
        'Kundnummer',
        'Namn',
      ]}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res.items.length > 0 &&
        res.items.map((row, index) => (
          <TableRow hover key={index}>
            <TableCell>
              <Link component={RouterLink} to={`/subscriptions/${row.id}`}>
                {row.product.name}
              </Link>
            </TableCell>
            <TableCell align="right">
              {paymentMethods[row.paymentMethod]}
            </TableCell>
            <TableCell align="right">
              <Time date={new Date(row.startDate)} />
            </TableCell>
            <TableCell align="right">
              <Time date={new Date(row.endDate)} />
            </TableCell>
            <TableCell>{getReasonText(row)}</TableCell>
            <TableCell>{row.user?.mobile}</TableCell>
            <TableCell>{row.user.email}</TableCell>
            <TableCell>{row.user.customerNumber}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
              {row.user?.name}
            </TableCell>
          </TableRow>
        ))}
    </TableList>
  );
}

CanceledSubscriptionsListResult.propTypes = {
  params: PropTypes.shape({
    paymentMethod: PropTypes.string,
    sites: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    inactiveUsersOnly: PropTypes.bool,
    fromEndDate: PropTypes.string,
    toEndDate: PropTypes.string,
  }),
};
